import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import accountApi from '@/api/accountApi'
import { Table } from 'antd'
import styles from './data.module.scss'

let socket: any = false


const Datadb: React.FC = () => {
  const [data, setData] = useState<any>()
  const [tableColumns, setTableColumns] = useState<any>()


  useEffect(()=>{
    getData(true)
    return () => {
      if(socket){
        socket = false
      }
    }
  }, [])

  const getData = (con?: boolean) => {
    accountApi.companys().then((result: any)=>{
      if(result.code === 200){
        socket = con
        if(result.data){
          let temp: any = [
            {
              title: '名次',
              dataIndex: 'name',
              render: (val: string, item: any, index: number) => (
                <span style={{whiteSpace: 'nowrap'}}>第 {index + 1} 名</span>
              )
            }
          ]
          let dataTemp: any = []
          result.data.results.map((item: any)=>{
            temp.push({
              title: item.category_name,
              dataIndex: 'id' + item.id,
              render: (val: string) => (
                <span dangerouslySetInnerHTML={{ __html: val }} />
              )
            })
            item.projects.forEach((c: any, index: number)=>{
              if(!dataTemp[index]){
                dataTemp[index] = {}
              }
              dataTemp[index]['id' + item.id] = `${c.project_name} (<strong>${c.sort_num}</strong>)`
            })
          })
          setTableColumns(temp)
          setData(dataTemp)
        }
        setTimeout(()=>{
          getData()
        }, 3000)
      }
    })
  }
  return (
    <div className={styles.box}>
      <div className='lay'>
        <Table bordered dataSource={data} pagination={false} columns={tableColumns} size="middle" /> 
      </div>
    </div>
  )
}

export default Datadb
