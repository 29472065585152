import axios from 'axios'

const accountApi: any = {}

accountApi.login = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/user/in', params)
}
accountApi.user = (params:{}):Promise<{}>=>{
  return axios.get('/api/users', { params })
}
accountApi.category = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/category/list', params)
}

accountApi.createCategory = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/category/create', params)
}

accountApi.setCategory = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/category/update', params)
}

accountApi.delCategory = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/category/delete', params)
}

accountApi.company = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/project/list', params)
}
accountApi.createCompany = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/project/create', params)
}
accountApi.setCompany = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/project/update', params)
}
accountApi.delCompany = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/project/delete', params)
}

accountApi.companys = (params:{}):Promise<{}>=>{
  return axios.post('/v1/admin/category/sort_list', { params })
}

export default accountApi