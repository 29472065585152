import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import accountApi from '@/api/accountApi'
import css from './login.module.scss'

const Login: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  function onFinish(values: any) {
    // setLoading(true)
    // accountApi.login(values).then((res: any) => {
    //   setLoading(false)
    //   if(res.code === 1) {
    //     localStorage.setItem("userInfo", JSON.stringify(res.data))
    //     props.history.push('/quotation')
    //   }
    // })
    accountApi.login(values).then((result: any)=>{
      if(result.code === 200){
        let url = '/category'
        if(values.username === 'data'){
          url = '/chart'
        }
        window.sessionStorage.setItem('token', result.data.token)
        window.sessionStorage.setItem('auth', result.data.auth)
        window.sessionStorage.setItem('username', values.username)
        history.push(url)
      }else {
        message.warning('登录失败');
      }
    })
  }
  return (
    <div className={css['login-pg']}>
      <div className={css['login-pg-wrap']}>
        <div className={css['login-bg-wrap']}>
          
          <div className={css['login-bg-rt']}>
            <div className={css['login-title-wrap']}>
              <span className={css['row-line']}></span>
              <span className={css['login-title']}>数据仓库plus</span>
              <span className={css['row-line']}></span>
            </div>
            <Form 
              form={form} 
              name="login_form" 
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入账号!' }]}
              >
                <Input 
                  placeholder="输入账号" 
                  size="large" 
                  style={{width: 300}}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
              >
                <Input
                  type="password"
                  placeholder="输入密码"
                  size="large"
                  style={{width: 300}}
                />
              </Form.Item>
              <Form.Item style={{marginTop: 40}}>
                <Button 
                  size="large" 
                  type="primary" 
                  htmlType="submit" 
                  className="login-form-button" 
                  style={{width: 300}}
                  loading={loading}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className={css.record}><a href='https://beian.miit.gov.cn' target="_blank">浙ICP备2021039730号-1</a></div>
    </div>
  )
}
export default Login