import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import accountApi from '@/api/accountApi'
import { Tabs, Button, Modal, Input, Spin, message, Popconfirm } from 'antd'
import styles from './home.module.scss'

const otherUser = ['data']

const Company: React.FC = () => {
  const username = window.sessionStorage.getItem("username") || ''
  const [data, setData] = useState<any>([])
  const [categoryData, setCategoryData] = useState<any>([])
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [active, setActive] = useState<any>({})

  const [searchKey, setSearchKey] = useState<string>('')


  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [delLoading, setDelLoading] = useState<boolean>(false)
  
  const [tabActive, setTabActive] = useState<string>('')
  const [tabData, setTabData] = useState<any>([
    {
      name: 'a',
      id: '1'
    },
    {
      name: 'b',
      id: '2'
    },
    {
      name: 'c',
      id: '3'
    },
    {
      name: 'd',
      id: '4'
    },
    {
      name: 'e',
      id: '5'
    }
  ])

  useEffect(()=>{
    getcategory()
  }, [])

  useEffect(()=>{
    if(tabActive && categoryData.length){
      setSearchKey('')
      getData()
    }
  }, [tabActive, categoryData])

  const getData = () => {
    setLoading(true)
    accountApi.company({category_id: categoryData[tabActive].id, page_size: 1000}).then((result: any)=>{
      setLoading(false)
      if(result.code === 200){
        if(result.data){
          setData(result.data.results)
        }else {
          setData([])
        }
        
      }
    })
  }
  const getcategory = () => {
    setCategoryLoading(true)
    accountApi.category().then((result: any)=>{
      setCategoryLoading(false)
      if(result.code === 200 && result.data){
        if(result.data.results.length){
          setTabActive('0')
        }
        setCategoryData(result.data.results)
      }
    })
  }
  const showModify = (item: any) => {
    let temp = JSON.parse(JSON.stringify(item))
    setActive(temp)
    setVisible(true)
  }
  const changeActive = (val: string, type: string) => {
    let temp: any = JSON.parse(JSON.stringify(active))
    temp[type] = val
    setActive(temp)
  }

  const submit = () => {
    let api: any = {}

    setSubmitLoading(true)
    active.sort_num = parseFloat(active.sort_num) ? parseFloat(active.sort_num) : 0
    active.category_id = categoryData[tabActive].id
    if(active.id){
      api = accountApi.setCompany
    }else {
      api = accountApi.createCompany
    }
    api(active).then((result: any)=>{
      setSubmitLoading(false)
      if(result.code === 201 || result.code === 200){
        getData()
        setVisible(false)
        message.success(active.id ? '修改成功！' : '新增成功！')
      }
    })
  }

  const confirm = (id: string, index: number) => {
    let temp = [...data]
    temp[index].loading = true
    setData(temp)
    accountApi.delCompany({id}).then((result: any)=>{
      if(result.code === 200){
        getData()
        message.success('操作成功！')
      }else {
        let temp = [...data]
        temp[index].loading = false
        setData(temp)
      }
    })
  }

  const changeData = (val: string, index: number) => {
    let temp = [...data]
    temp[index].sort_num = val
    setData(temp)
  }

  const submitCount = (index: number) => {
    let temp = [...data]
    temp[index].loading = true
    temp[index].sort_num = parseFloat(temp[index].sort_num) ? parseFloat(temp[index].sort_num) : 0
    setData(temp)
    accountApi.setCompany(temp[index]).then((result: any)=>{
      if(result.code === 200){
        // getData()
        message.success('操作成功！')
      }else {
        let temp = [...data]
        temp[index].loading = false
        setData(temp)
      }
    })
  }

  return (
    <>
      <Spin spinning={categoryLoading}>
        {
          categoryData.length ? (
            <div className={styles.box}>
              <Tabs onChange={setTabActive} type="card" activeKey={tabActive}>
                {
                  categoryData.map((item: any, index: number)=>{
                    return (
                      <Tabs.TabPane tab={item.category_name} key={index}></Tabs.TabPane>
                    )
                  })
                }
              </Tabs>
              <div className={styles.search}>
                <div className={styles.l}>
                  <Input allowClear className={styles.key} placeholder="搜索" value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />
                  {
                    otherUser.indexOf(username) === -1 ? (
                      <Button type="primary" onClick={()=>showModify({})}>新增</Button>
                    ): null
                  }
                </div>
              </div>
              
              <div className={styles.content}>
                <Spin spinning={loading}>
                  {
                    data.map((item: any, index: number)=>{
                      return (
                        !searchKey || item.name_initcal.indexOf(searchKey) !== -1 || item.project_name.indexOf(searchKey) !== -1 ? (
                          <div className={styles.item} key={index}>
                            <h5>
                              {item.project_name} 
                              {
                                otherUser.indexOf(username) === -1 ? (
                                  <span onClick={()=>showModify(item)}>[修改]</span>
                                ): null
                              }
                            </h5>
                            <div>
                              <Input value={item.sort_num} onChange={(e)=>changeData(e.target.value, index)} />
                              <Button type="primary" onClick={()=>submitCount(index)}>保存</Button>
                              {
                                otherUser.indexOf(username) === -1 ? (
                                  <Popconfirm
                                    title="确定删除 ?"
                                    onConfirm={() => confirm(item.id, index)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button type="link" loading={delLoading} size="small" danger>删除</Button>
                                  </Popconfirm>
                                ): null
                              }
                            </div>
                          </div>
                        ): null
                      )
                    })
                  }
                </Spin>
              </div>
              
            </div>
          ): (
            <div className={styles.box}>
              <Link to="/category"><Button type="primary">添加分类</Button></Link>
            </div>
          )
        }
      </Spin>
      <Modal
        visible={visible}
        footer={null}
        destroyOnClose
        width={300}
        onCancel={()=>setVisible(false)}
      >
        <div className={styles.modify}>
          <h5>{active.id ? '修改' : '新增'}</h5>
          <div className={styles.item}>
            <label>名称</label>
            <Input value={active.project_name} onChange={(e)=>changeActive(e.target.value, 'project_name')} />
          </div>
          <div className={styles.item}>
            <label>搜索字母</label>
            <Input value={active.name_initcal} onChange={(e)=>changeActive(e.target.value, 'name_initcal')} />
          </div>
          <div className={styles.item}>
            <label>计数</label>
            <Input value={active.sort_num} onChange={(e)=>changeActive(e.target.value, 'sort_num')} />
          </div>
          <Button type="primary" loading={submitLoading} block onClick={()=>submit()}>保存</Button>
        </div>
      </Modal>
    </>
  )
}

export default Company
