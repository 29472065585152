import React, { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import accountApi from '@/api/accountApi'
import { Table } from 'antd'
import styles from './data.module.scss'

import icon from '@/assets/images/icon.png'

const DataAnimation: React.FC = () => {
  const [data, setData] = useState<any>([])
  const [row, setRow] = useState<any>([])
  const [tableColumns, setTableColumns] = useState<any>([])


  useEffect(()=>{
    getData(true)
  }, [])

  const getData = (con?: boolean) => {
    accountApi.companys().then((result: any)=>{
      if(result.code === 200){
       
        if(result.data){
          let max = 0
          let row: any = []
          result.data.results.forEach((item: any)=>{
            if(max < item.projects.length ){
              max = item.projects.length
            }
            // item.projects.forEach((pro: any, i: number)=>{
            //   let len = (pro.project_name.length) + Math.ceil((pro.sort_num.toString().length)/2) + 2
            //   let num = Math.ceil(len/18)
            //   if(!row[i] || row[i] < num){
            //     row[i] = num
            //   }
            // })
          })
          
          let ranking: any = {
            category_name: '名次',
            id: 'ranking',
            projects: []
          }
          for(let i=0; i<max; i++){
            ranking.projects.push(
              {
                id: 'ranking'+ (i+1),
                project_name: '第 '+(i+1)+' 名'
              }
            )
          }
          result.data.results.forEach((item: any)=>{
            let s = max - item.projects.length
            item.category_name = item.category_name.replace('(', "<em>(")
            item.category_name = item.category_name.replace(')', ")<em>")
            item.category_name = item.category_name.replace('（', "<em>(")
            item.category_name = item.category_name.replace('）', ")<em>")
            
            if(s){
              for(let i=0; i<s; i++){
                item.projects.push(
                  {
                    id: 'rankinga'+ (i+1),
                    project_name: ''
                  }
                )
              }
            }
          })
          result.data.results.unshift(ranking)
          setData(result.data.results)
          setRow(row)
          
        }
        setTimeout(()=>{
          getData()
        }, 3000)
      }
    })
  }
  return (
    <div className={styles.animation}>
      <div className={styles.animationTab}>
        {
          data.map((item: any, index: number)=>{
            return (
              <div key={item.id} className={styles.flex} style={{flex: index ? '1' : '0 1 auto'}}>
                {/* <h1 style={{lineHeight: (!index ? '3vw' : '')}} dangerouslySetInnerHTML={{ __html: item.category_name }}></h1> */}
                <h1 dangerouslySetInnerHTML={{ __html: item.category_name }}></h1>
                <FlipMove
                  staggerDurationBy="30"
                  duration={500}
                  typeName="div"
                  enterAnimation="accordionVertical"
                  leaveAnimation="accordionVertical"
                >
                  {
                    item.projects.map((pro: any, i: number)=>{
                      return (
                        // <p key={pro.id +''+item.id} style={{height: ((row[i] === 1 ? 3 : (row[i] * 2))+'vw'), lineHeight: (row[i] === 1 ? '2vw' : ''), whiteSpace: (index ? 'normal' : 'nowrap')}}>
                        <p key={pro.id +''+item.id}style={{whiteSpace: (index ? 'normal' : 'nowrap')}}>
                          {pro.project_name} {pro.sort_num ? <span dangerouslySetInnerHTML={{ __html: `(<strong>${pro.sort_num}</strong>)` }} /> : ''}
                          {
                            !i ? <img src={icon} /> : null
                          }
                          
                        </p>
                      )
                    })
                  }
                </FlipMove>
              </div>
            )
          })
           
            // data[0]?.projects.map((pro: any)=>{
            //   return (
            //     <p key={pro.id}>{pro.project_name}</p>
            //   )
            // })
          
        }
      
        {/* <>
          <div className={styles.flex}>
            {
              tableColumns.map((item: any)=>{
                return (
                  <div key={item.dataIndex}>{item.title}</div>
                )
              })
            }
          </div>
          
          {
            data.map((item: any, index: number)=>{
              return (
                <FlipMove
                  staggerDurationBy="30"
                  duration={500}
                  typeName="div"
                  enterAnimation="accordionVertical"
                  leaveAnimation="accordionVertical"
                  key={item.id+'c'}
                >
                  <div key={item.id} className={styles.flex}>
                    {
                      tableColumns.map((column: any, i: number)=>{
                        return (
                          <div key={item[column.dataIndex]?.id || index+'c'+i}>
                            {
                              column.render ? column.render(item[column.dataIndex], item, index) : item[column.dataIndex]
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </FlipMove>
              )
            })
          }
        </> */}
      </div>
      {/* <table>
        <tr>
          {
            tableColumns.map((item: any)=>{
              return (
                <th>{item.title}</th>
              )
            })
          }
        </tr>
        
        {
          data.map((item: any, index: number)=>{
            return (
              <tr>
                {
                  tableColumns.map((column: any)=>{
                    return (
                      <td>
                        {
                          column.render ? column.render(item[column.dataIndex], item, index) : item[column.dataIndex]
                        }
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </table> */}
      {/* <Table bordered dataSource={data} pagination={false} columns={tableColumns} size="middle" />  */}
      {/* <FlipMove
        staggerDurationBy="30"
        duration={500}
        typeName="div"
        enterAnimation="accordionVertical"
        leaveAnimation="accordionVertical"
      >
        <>
          <div>
            {
              ranking.map((name: any)=>{
                return (
                  <p>{name}</p>
                )
              })
            }
          </div>
          {
            data.map((item: any)=>{
              return (
                <div key={item.id}>
                  {
                    item.projects.map((pro: any)=>{
                      return (
                        <div key={item.id + '' + pro.category_id}>{pro.project_name} (<strong>{pro.sort_num}</strong>)</div>
                      )
                    })
                  }
                  
                </div>
              )
            })
          }
        </>
      </FlipMove> */}
    </div>
    
  )

  // const [data, setData] = useState<any>([
  //   {
  //     val: {
  //       num: 1
  //     }
  //   },
  //   {
  //     val: {
  //       num: 2
  //     }
  //   },
  //   {
  //     val: {
  //       num: 3
  //     }
  //   },
  //   {
  //     val: {
  //       num: 4
  //     }
  //   },
  //   {
  //     val: {
  //       num: 5
  //     }
  //   },
  //   {
  //     val: {
  //       num: 6
  //     }
  //   }
  // ])
  // const submit = () => {
  //   setData([
  //     {
  //       val: {
  //         num: 1
  //       }
  //     },
  //     {
  //       val: {
  //         num: 2
  //       }
  //     },
  //     {
  //       val: {
  //         num: 6
  //       }
  //     },
  //     {
  //       val: {
  //         num: 4
  //       }
  //     },
  //     {
  //       val: {
  //         num: 5
  //       }
  //     },
  //     {
  //       val: {
  //         num: 3
  //       }
  //     }
  //   ])
  // }
  // return (
  //   <div>
  //     <div>
        
  //       <FlipMove
  //         staggerDurationBy="30"
  //         duration={500}
  //         typeName="table"
  //         enterAnimation="accordionVertical"
  //         leaveAnimation="accordionVertical"
  //       >
  //         {
  //           data.map((i: any, index: number)=>{
  //             return (
  //               <tr key={i.val.num}>
  //                 <td style={{color: '#000'}}>{i.val.num}</td>
  //               </tr>
  //             )
  //           })
  //         }
  //       </FlipMove>
        
  //     </div>
  //     <button onClick={()=>submit()}>submit</button>
  //   </div>
  // )
}

export default DataAnimation