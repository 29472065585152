import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import style from './main.module.scss'

interface Parameter {
  type?: string,
  update?: Function
}

const Header: React.FC<Parameter> = (props: Parameter) => {
  const history = useHistory()
  const [userInfo, setUserInfo] = useState<any>({})
  useEffect(()=>{
    getUserInfo()
  },[])
  const getUserInfo = () => {
    
  }
  const logout = () => {
    window.sessionStorage.removeItem('token')
    window.sessionStorage.removeItem('auth')
    history.push('/login')
  }
  return (
    <div className={style['header']}>
      <div className={style['l']}>
        大屏数据系统
      </div>
      <div className={style['r']}>
        {/* <div className={style['item']}>{userInfo.name || 'username'}</div> */}
        <div className={style['item']} onClick={() => logout()}> 退出登录</div>
      </div>
    </div>
  )
}

export default Header;