import React, { useEffect } from 'react'
import axios from 'axios'

let ResInterceptors: React.FC = () => {
  
  useEffect(()=>{
    init()
  },[])

  const init = () => {
    axios.interceptors.request.use(config => {
      
      config.headers['auth'] = window.sessionStorage.getItem("auth") || '';
      config.headers['token'] = window.sessionStorage.getItem("token") || '';

      return config;
    }, err => {
      return Promise.reject(err);
    })

    axios.interceptors.response.use((response) => {
      if(response.data.code === 203){
        window.location.href = '/login'
      }
      return response.data || {};
    }, err => {
      return Promise.reject(err);
    })
  }

  return null
}

export default ResInterceptors



