import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import Routes from '@/Routes'
import { Header, Menu } from '@/page/Main'
import zhCN from 'antd/lib/locale/zh_CN'
import style from './main.module.scss'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

const Main: React.FC = () => {
  const [key, setKey] = useState<number>(1)
  const other = ['/login', '/data', '/dataanimation', '/chart']
  const { pathname } = useLocation()
  const update = () => {
    setKey(key+1)
  }
  return (
    other.indexOf(pathname) === -1 && pathname !== '/' ? (
      <>
        <Header type='main' update={update} />
        <Menu />
        <div className={style['content']}>
          <ConfigProvider locale={zhCN}>
            <Routes key={key} />
          </ConfigProvider>
        </div>
      </>
    ): (
      <Routes />
    )
  );
}

export default Main