import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './main.module.scss'

const menu = [
  {
    title: '分组管理',
    path: '/category'
  },
  {
    title: '公司管理',
    path: '/company'
  }
]

const Menu: React.FC = () => {
  const { pathname } = useLocation()
  const [userInfo, setUserInfo] = useState<any>({})
  
  return (
    <div className={style['menu']}>
      <ul>
        {
          menu.map((item, index)=>{
            return (
              <Link to={item.path} key={index}>
                <li className={pathname.indexOf(item.path) !== -1 ? style['active'] : ''}>{item.title}</li>
              </Link>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Menu;