import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

interface PropsIn {
  option: any,
  ud: number
}

const Echart: React.FC<PropsIn> = (props: any) => {

  const chartsNode = useRef(null)
  const ec: any = useRef(null)

  useEffect(()=>{
    initChart()
  }, [])

  useEffect(()=>{
    updateData()
  }, [props.ud])
  
  const initChart = () => {
    ec.current = chartsNode.current && echarts.init(chartsNode.current)
    ec.current.setOption(props.option)
  }
  const updateData = () => {
    ec.current?.setOption(props.option)
  }
  return (
    <div ref={chartsNode} style={{width: '100%', height: '100%'}} />
  )
  
}

export default Echart