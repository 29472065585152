import React, { useEffect, useState } from 'react'
import accountApi from '@/api/accountApi'
import { Table } from 'antd'
import * as echarts from 'echarts'
import Echart from '@/components/Echart'
import styles from './data.module.scss'
import logoImg from '@/assets/images/logo.png'

let socket: any = false

const option = {
  xAxis: {
    show: false,
    max: 'dataMax'
  },
  yAxis: {
    show: false,
    type: 'category',
    data: [],
    inverse: true,
    animationDuration: 300,
    animationDurationUpdate: 300,
    max: 7 // only the largest 3 bars will be displayed
  },
  series: [
    {
      realtimeSort: true,
      name: 'X',
      type: 'bar',
      data: [],
      label: {
        show: true,
        position: 'insideLeft',
        valueAnimation: true,
        formatter: '{b}({c})',
        fontWeight: 400,
        fontSize: 24,
        fontFamily: 'Microsoft YaHei'
      },
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: 'rgb(255, 0, 135)' },
          { offset: 1, color: 'rgb(135, 0, 157)' }
        ])
      }
    }
  ],
  animationDuration: 0,
  animationDurationUpdate: 3000,
  animationEasing: 'linear',
  animationEasingUpdate: 'linear'
}

const Chart: React.FC = () => {
  const [data, setData] = useState<any>([])


  useEffect(()=>{
    getData(true, [])
    return () => {
      if(socket){
        socket = false
      }
    }
  }, [])

  const getData = (con: boolean, dtemp: any) => {
    accountApi.companys().then((result: any)=>{
      if(result.code === 200){
        socket = con
        if(result.data){

          let max: any = {
            category_name: '苏皖大区第一名',
            projects: [result.data.results[0].projects[0]]
          }
          // let maxindex = result.data.results - 1
          result.data.results.map((item: any)=>{
            if(item.projects[0]){
              if(item.projects[0].sort_num > max.projects[0].sort_num){
                max.projects = [item.projects[0]]
              }
            }
          })
          result.data.results.unshift(max)

          result.data.results.map((item: any, index: number)=>{
            let temp = JSON.parse(JSON.stringify(option))
            // if(item.category_name.includes('苏北') || item.category_name.includes('南京')){
            //   temp.yAxis.max = 7
            // }
            if(index % 2 === 0){
              temp.series[0].itemStyle.color = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgb(55, 162, 255)' },
                { offset: 1, color: 'rgb(116, 21, 219)' }
              ])
            }
            
            item.projects.forEach((pro: any)=>{
              temp.yAxis.data.push(pro.project_name)
              temp.series[0].data.push(pro.sort_num)
            })
            item.ud = dtemp[index]?.ud || 1
            if(JSON.stringify(dtemp[index]?.option) !== JSON.stringify(temp)){
              item.ud = item.ud + 1
            }
            item.option = temp
          })
          setData(result.data.results)
          setTimeout(()=>{
            getData(false, JSON.parse(JSON.stringify(result.data.results)))
          }, 3000)
        }else {
          setTimeout(()=>{
            getData(false, [])
          }, 3000)
        }
        
      }
    })
  }
  
  return (
    <div className={styles.chart}>
      {
        data.map((item: any)=>{
          return (
            <div className={styles.item}>
              <h2><img src={logoImg} alt="logo" /> <span>{item.category_name}</span></h2>
              {/* {
                item.category_name.includes('苏北') || item.category_name.includes('南京') ? (
                  <Echart option={item.option} ud={item.ud} key={item.id} />
                ): (
                  <div style={{height: '80%'}}>
                    <Echart option={item.option} ud={item.ud} key={item.id} />
                  </div>
                )
              } */}
              <Echart option={item.option} ud={item.ud} key={item.id} />
            </div>
          )
        })
      }
    </div>
  )
}

export default Chart
